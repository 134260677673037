// src/App.js

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loginpage from "./pages/Loginpage";

import Homepage from "./pages/Homepage";
import SignUpPage from "./pages/SignUpPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import { useSelector } from "react-redux/es/hooks/useSelector";

function App() {
  const isLogged = useSelector((state) => state.isLogged);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Loginpage />} />
          <Route path="/Loginpage" element={<Loginpage />} />

          <Route
            path="/Homepage"
            element={
              <ProtectedRoute>
                <Homepage />
              </ProtectedRoute>
            }
          />

          <Route path="/Signup" element={<SignUpPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
