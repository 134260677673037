export const sendFeedback = async (sessionID, itemId, feedback) => {
    var formdata = new FormData();
    formdata.append("session_id", sessionID);
    formdata.append("conversation_id", itemId);
    formdata.append("feedback", feedback);
    formdata.append("other", "gqLBR5gB85bA21rCiYFnDlpU5EK2");
  
    var requestOptions = {
      method: "POST",
      body: formdata,
    };
  
    try {
      const fetchResponse = await fetch("https://translate.bettermos.com/api/v1/feedback", requestOptions);
      if (fetchResponse.ok) {
        const data = await fetchResponse.json();
        return data;
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };