import "../App.css";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Footermenu from "../components/footermenu/Footermenu";
import Translator from "../components/translator/Translator";
import FeedbackToast from "../components/toast/FeedbackToast";
import { saveAs } from "file-saver"; // To handle file downloads

function Homepage() {
  const [sessionID, setSessionID] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState("Nederlands");
  const [targetLanguage, setTargetLanguage] = useState("Engels");
  const [messages, setMessages] = useState([]);
  const [languages, setLanguages] = useState();
  const [loading, setLoading] = useState(false);

  const switchAIBaseUrl = "https://translate.bettermos.com/api/v1/";

  // Speaker Mapping
  const speakerMap = {
    A: "User1",
    B: "User2",
  };

  useEffect(() => {
    const urlToFetch = `${switchAIBaseUrl}languages`;
    const fetchLanguages = async () => {
      setLoading(true);
      try {
        const response = await fetch(urlToFetch);
        const languages = await response.json();

        setLanguages(languages);
        setSourceLanguage(languages.source_languages[1]);
        setTargetLanguage(languages.source_languages[0]);
      } catch (error) {
        console.error(
          "Error when fetching languages from SwitchAI API:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const urlToFetchID = `${switchAIBaseUrl}session-id`;
    const fetchSessionID = async () => {
      try {
        const response = await fetch(urlToFetchID);
        const sessionIDresponse = await response.json();
        setSessionID(sessionIDresponse.session_id);
      } catch (error) {
        console.error("Error when getting sessionID from SwitchAI API:", error);
      }
    };

    fetchSessionID();
  }, []);

  // Toast
  const [showToast, setShowToast] = useState({
    toggle: false,
    rating: 0,
    isTranslationFeedback: null,
  });

  // Export Conversation Handler
  const handleExportConversation = async () => {
    if (messages.length === 0) {
      alert("Geen gesprekken om te exporteren.");
      return;
    }

    // Format messages into plain text with both original and translation
    const conversationText = [...messages] 
      .reverse() // Reverse to get chronological order
      .map((msg) => {
        const timestamp = new Date(msg.timestamp || Date.now()).toLocaleString();
        const speaker = speakerMap[msg.mic] || "Unknown";
        const text =
          msg.mic === "A"
            ? msg.text || "Geen tekst beschikbaar"
            : msg.mic === "B"
            ? msg.text || "Geen tekst beschikbaar"
            : msg.text || "Geen tekst beschikbaar"; // Adjust as needed

        // Assuming each message contains both original and translation
        return `[${timestamp}] ${speaker}: ${text}\n[${timestamp}] Translator: ${msg.translation || "Geen vertaling beschikbaar"}\n-----------------------------`;
      })
      .join("\n\n");

    // Check if Web Share API is available (iOS and modern browsers)
    if (
      navigator.share &&
      navigator.canShare &&
      navigator.canShare({ files: [new File([], "conversation.txt")] })
    ) {
      try {
        const blob = new Blob([conversationText], { type: "text/plain" });
        const file = new File([blob], "conversation.txt", { type: "text/plain" });

        await navigator
          .share({
            title: "Gespreks Transcript",
            text: "Hier is de transcript van je gesprek:",
            files: [file],
          })
          .then(() => {
            console.log("Deeldialog geopend.");
          })
          .catch((error) => {
            console.error("Fout bij delen:", error);
            alert("Er is een fout opgetreden bij het delen van de transcript. Het bestand wordt gedownload.");
            downloadTextFile(conversationText);
          });
      } catch (error) {
        console.error("Fout bij het gebruik van Share API:", error);
        alert("Er is een fout opgetreden bij het exporteren. Het bestand wordt gedownload.");
        downloadTextFile(conversationText);
      }
    } else {
      // Fallback for browsers that do not support Web Share API
      downloadTextFile(conversationText);
    }
  };

  // Helper function to download text as a file
  const downloadTextFile = (text) => {
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "conversation.txt");
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar handleExportConversation={handleExportConversation} />

      <div className="container translator">
        <div className="container">
          <div className="row justify-content-center mt-3 pt-3">
            <div className="col-11 mt-3">
              <Translator
                messages={messages}
                setMessages={setMessages}
                sourceLanguage={sourceLanguage}
                showToast={showToast}
                setShowToast={setShowToast}
                sessionID={sessionID}
              />
            </div>
          </div>
        </div>
        <div className="pb-8">
          {!loading ? (
            <Footermenu
              messages={messages}
              setMessages={setMessages}
              sourceLanguage={sourceLanguage}
              targetLanguage={targetLanguage}
              setSourceLanguage={setSourceLanguage}
              setTargetLanguage={setTargetLanguage}
              languages={languages}
              sessionID={sessionID}
            />
          ) : (
            <div className="container text-center my-3">
              <div className="row">
                <div className="col">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
                <div className="col">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <FeedbackToast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
}

export default Homepage;
