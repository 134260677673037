import React from "react";
import MicrophoneBase from "./MicrophoneBase";
import "./microphone2.css";

function Microphone2(props) {
  return (
    <MicrophoneBase
      {...props}
      micId="B"
      targetGender="female"
      tooltipClass="tooltip2"
      btnClass="btn btn-secondary micbtn custom-mic2-btn"
      spinnerClass="spinner-border text-dark custom-spinner"
      blinkIconClass="bi bi-stop-circle blink-icon2 display-3"
      iconClass="text-orange"
    />
  );
}

export default Microphone2;
