import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ loggedIn, children }) => {
  const isLogged = useSelector((store) => store.isLogged);

  if (!isLogged) {
    return <Navigate to="/Loginpage" replace />;
  }

  return children;
};

export default ProtectedRoute;
