import React, { useEffect, useState, useRef } from "react";
import { useMicrophone } from "../../hooks/useMicrophone";
import { translateAudioToText, textToAudio } from "../../api/translationApi";
import { generateId } from "../utilities/utilities";
import Tooltip from "../Tooltip";
import MicButton from "../MicButton";
import LoadingMessage from "./LoadingMessage";

function MicrophoneBase({
  transcript,
  resetTranscript,
  browserSupportsSpeechRecognition,
  messages,
  setMessages,
  sourceLanguage,
  targetLanguage,
  micInstance,
  setMicInstance,
  sessionID,
  conversationID,
  micId,
  targetGender,
  tooltipClass,
  btnClass,
  spinnerClass,
  blinkIconClass,
}) {
  const [audioStream, setAudioStream] = useState(null); // Store the captured audio stream
  const { startRecording, stopRecording, cleanup } = useMicrophone();
  const [listening, setListening] = useState(false);
  const [userRecording, setUserRecording] = useState(null);
  const [translatedAudio, setTranslatedAudio] = useState(null);
  const [transcribedText, setTranscribedText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [tooltipShow, setTooltipshow] = useState("tooltipHide");
  const messageAddedRef = useRef(false);
  const readyRef = useRef({
    transcribedText: false,
    translatedText: false,
    translatedAudio: false,
  });
  const currentAudioRef = useRef(null); // Add this ref

  const addLoadingMessage = () => {
    if (!messageAddedRef.current) {
      const loadingMessage = LoadingMessage({
        sourceLanguage,
        targetLanguage,
        micId,
        sessionID,
        conversationID,
        timestamp: new Date().toISOString(), // Add current timestamp
      });
      setMessages((prevMessages) => [loadingMessage, ...prevMessages]);
      messageAddedRef.current = true;
    }
  };

  const removeLoadingMessage = () => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== "loadingmessage")
    );
    messageAddedRef.current = false;
  };

  const handleTranscriptFinalized = () => {
    if (
      readyRef.current.transcribedText &&
      readyRef.current.translatedText &&
      readyRef.current.translatedAudio
    ) {
      const newMessage = {
        id: generateId(),
        sessionID: sessionID,
        conversationID: conversationID,
        mic: micId,
        text: transcribedText,
        userAudio: userRecording.src,
        language: sourceLanguage,
        languageTr: targetLanguage,
        translation: translatedText,
        translationAudio: translatedAudio,
        feedbackRating: 0,
        timestamp: new Date().toISOString(), // Assign current timestamp
      };
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
      setTranscribedText("");
      setTranslatedText("");
      setTranslatedAudio(null);
      readyRef.current = {
        transcribedText: false,
        translatedText: false,
        translatedAudio: false,
      };
    }
  };

  useEffect(() => {
    if (
      readyRef.current.transcribedText &&
      readyRef.current.translatedText &&
      readyRef.current.translatedAudio
    ) {
      handleTranscriptFinalized();
      removeLoadingMessage();
      setLoading(false);
    }
  }, [transcribedText, translatedText, translatedAudio]);

  const handleAudioPlay = (audioPlayer) => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
      currentAudioRef.current = null;
    }

    audioPlayer.play()
      .then(() => {
        currentAudioRef.current = audioPlayer;
        audioPlayer.onended = () => {
          currentAudioRef.current = null;
        };
      })
      .catch((error) => {
        console.log("Play error:", error);
      });
  };

  const onClick = async () => {
    if (listening && micInstance === micId) {
      setLoading(true);
      addLoadingMessage();
      try {
        const audioBlob = await stopRecording();
        if (audioBlob.size === 0) {
          throw new Error(
            "Audio blob is empty, possibly due to permission issues."
          );
        }
        const audioSrc = URL.createObjectURL(audioBlob);
        const audioPlayer = new Audio(audioSrc);
        setUserRecording(audioPlayer);

        const { transcribed_text: transcribed, translated_text: translated } =
          await translateAudioToText(
            audioBlob,
            sourceLanguage,
            targetLanguage,
            sessionID,
            conversationID
          );
        setTranscribedText(transcribed);
        setTranslatedText(translated);

        readyRef.current.transcribedText = true;
        readyRef.current.translatedText = true;

        const audioResponseBlob = await textToAudio(
          translated,
          targetLanguage,
          targetGender,
          sessionID,
          conversationID
        );
        const audioResponseSrc = URL.createObjectURL(audioResponseBlob);
        const audioResponsePlayer = new Audio(audioResponseSrc);

        setTranslatedAudio(audioResponsePlayer);
        handleAudioPlay(audioResponsePlayer); // Use the new handler
        readyRef.current.translatedAudio = true;
      } catch (error) {
        console.error("Error during translation process:", error);
        setLoading(false);
        removeLoadingMessage();
      } finally {
        cleanup();
        setListening(false);
      }
    } else {
      setMicInstance(micId);
      setListening(true);
      await startRecording();
    }
  };

  // Initialize audio stream on page load
  useEffect(() => {
    const initAudioStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setAudioStream(stream); // Save the audio stream for later use
      } catch (error) {
        console.error("Error initializing audio stream:", error);
      }
    };

    initAudioStream();
  }, []);

  return (
    <>
      <Tooltip tooltipShow={tooltipShow} sourceLanguage={sourceLanguage} />
      <MicButton
        btnClass={btnClass}
        onClick={onClick}
        loading={loading}
        listening={listening}
        micInstance={micInstance}
        micId={micId}
        spinnerClass={spinnerClass}
        blinkIconClass={blinkIconClass}
      />
    </>
  );
}

export default MicrophoneBase;
