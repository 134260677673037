import React from "react";
import FeedbackThumbs from "../feedback/FeedbackThumbs";

function Message({
  item,
  handleTextClick,
  handleResponseTextClick,
  messages,
  setMessages,
  showToast,
  setShowToast,
  sessionID,
}) {
  return (
    <div key={item.id} className="message-container">
      <div className="text-center">
        <p className="">
          {item.language.display_name} - {item.languageTr.display_name}
        </p>
      </div>
      <div className={item.mic === "A" ? "text-start" : "text-end"}>
        {/* Original Text */}
        <div className="btn-group-vertical py-2">
          <button
            type="button"
            className={`btn btn-smaller ${item.mic === "A" ? "btn-primary" : "btn-secondary"} text-start`}
          >
            <div className="d-flex justify-content-between">
              <div className="col" onClick={() => handleTextClick(item.id)}>
                {item.id === "loadingmessage" ? (
                  <div className="container">
                    <div className="snippet" data-title="dot-flashing">
                      <div className="stage">
                        <div className="dot-flashing mx-3"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  item.text || "No text available"
                )}
              </div>
              <div>
                <FeedbackThumbs
                  messages={messages}
                  setMessages={setMessages}
                  itemId={item.id + 1 + "a"}
                  showToast={showToast}
                  setShowToast={setShowToast}
                  sessionID={sessionID}
                  isTranslationFeedback={false}
                />
              </div>
            </div>
          </button>
          <button
            type="button"
            className="btn btn-smaller btn-light text-start"
          >
            <div className="d-flex justify-content-between">
              <div
                className="col"
                onClick={() => handleResponseTextClick(item.id)}
              >
                {item.id === "loadingmessage" ? (
                  <div className="container">
                    <div className="snippet" data-title="dot-flashing">
                      <div className="stage">
                        <div className="dot-flashing mx-3"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  item.translation || "No translation available"
                )}
              </div>
              <div>
                <FeedbackThumbs
                  messages={messages}
                  setMessages={setMessages}
                  itemId={item.id + "b"}
                  showToast={showToast}
                  setShowToast={setShowToast}
                  sessionID={sessionID}
                  isTranslationFeedback={true}
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Message;
