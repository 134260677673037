import React from "react";
import { useState } from "react";
import { sendFeedback } from "../../api/feedbackApi";

// rating variables
const noRating = 0;
const negRating = 1;
const posRating = 2;

export default function FeedbackThumbs({
  messages,
  setMessages,
  itemId,
  showToast,
  setShowToast,
  sessionID,
  isTranslationFeedback
}) {
  const [rating, setRating] = useState(noRating);

  const handleThumbsUp = async () => {
    // toggle rating icon
    setRating((prevRating) =>
      prevRating === posRating ? noRating : posRating
    );
    // update rating to positive for message
    const updatedMessageRating = messages.map((message) => {
      if (message.id === itemId) {
        return {
          ...message,
          feedbackRating: posRating,
        };
      } else {
        return message;
      }
    });
    // Re-render with the new array
    setMessages(updatedMessageRating);
    // set toast
    setShowToast({
      toggle: true,
      rating: posRating,
      isTranslationFeedback: isTranslationFeedback,
    });

    // send feedback to API
    await sendFeedback(sessionID, itemId, "good");
  };

  const handleThumbsDown = async () => {
    // toggle rating icon
    setRating((prevRating) =>
      prevRating === negRating ? noRating : negRating
    );

    // update rating to negative for message
    const updatedMessageRating = messages.map((message) => {
      if (message.id === itemId) {
        return {
          ...message,
          feedbackRating: negRating,
        };
      } else {
        return message;
      }
    });
    // Re-render with the new array
    setMessages(updatedMessageRating);
    // set toast
    setShowToast({
      toggle: true,
      rating: negRating,
      isTranslationFeedback: isTranslationFeedback,
    });

    // send feedback to API
    await sendFeedback(sessionID, itemId, "bad");
  };

  return (
    <div>
      <div
        onClick={() => {
          handleThumbsUp();
        }}
        className="badge badge-light custom-badge ms-4 px-0"
      >
        <i
          className={`bi  ${
            rating === 2 ? "bi-hand-thumbs-up-fill" : "bi-hand-thumbs-up"
          }`}
        ></i>
      </div>
      <div
        onClick={() => {
          handleThumbsDown();
        }}
        className="badge badge-light custom-badge px-2 "
      >
        <i
          className={`bi ${
            rating === 1 ? "bi-hand-thumbs-down-fill" : "bi-hand-thumbs-down"
          }`}
        ></i>
      </div>
    </div>
  );
}
