import React, { useEffect, useRef } from "react";
import "./translator.css";
import Message from "./Message";

function Translator({ messages, setMessages, sourceLanguage, showToast, setShowToast, sessionID }) {
  const currentAudioRef = useRef(null); // Ref to track the currently playing audio

  useEffect(() => {
    console.log("Messages updated:", messages);
  }, [messages]);

  const handleAudioClick = (audioSrc) => {
    // If there's an audio currently playing
    if (currentAudioRef.current) {
      // If the clicked audio is the same as the current one
      if (currentAudioRef.current.src === audioSrc) {
        if (!currentAudioRef.current.paused) {
          currentAudioRef.current.pause();
          currentAudioRef.current.currentTime = 0;
          currentAudioRef.current = null;
          return;
        }
      } else {
        // Stop the currently playing audio
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
        currentAudioRef.current = null;
      }
    }

    // Play the new audio
    const audioPlayer = new Audio(audioSrc);
    audioPlayer.play()
      .then(() => {
        currentAudioRef.current = audioPlayer;
        // When the audio ends, clear the ref
        audioPlayer.onended = () => {
          currentAudioRef.current = null;
        };
      })
      .catch((error) => {
        console.error("Play error:", error);
      });
  };

  const handleTextClick = (messageId) => {
    const clickedMessage = messages.find((message) => message.id === messageId);
    if (clickedMessage && clickedMessage.userAudio) {
      handleAudioClick(clickedMessage.userAudio);
    }
  };

  const handleResponseTextClick = (messageId) => {
    const clickedMessage = messages.find((message) => message.id === messageId);
    if (clickedMessage && clickedMessage.translationAudio) {
      handleAudioClick(clickedMessage.translationAudio.src);
    }
  };

  useEffect(() => {
    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
        currentAudioRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <br />
      {messages && messages.length > 0 ? (
        // Reverse the messages array before mapping
        messages.slice().reverse().map((item, index) => (
          <Message
            key={`${item.id}-${index}`}
            item={item}
            handleTextClick={handleTextClick}
            handleResponseTextClick={handleResponseTextClick}
            messages={messages}
            setMessages={setMessages}
            showToast={showToast}
            setShowToast={setShowToast}
            sessionID={sessionID}
          />
        ))
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Translator;
