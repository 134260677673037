import React from "react";

const Tooltip = ({ tooltipShow, sourceLanguage }) => {
  return (
    <div id="tooltip" className={tooltipShow} role="tooltip">
      {(() => {
        switch (sourceLanguage.language_code) {
          case "en":
            return "Click again to stop recording";
          case "nl":
            return "Klik nogmaals om de opname te stoppen";
          case "ar":
            return "انقر مرة أخرى لإيقاف التسجيل";
          default:
            return "Klik nogmaals om de opname te stoppen";
        }
      })()}
      <div id="arrow" data-popper-arrow></div>
    </div>
  );
};

export default Tooltip;